import React from 'react';
import { Button, Modal, ModalBody, TabContent, TabPane } from 'reactstrap';

type ScanModalProps = {
  show: boolean;
  onCloseClick: () => void;
};

const ScanModal = ({ show, onCloseClick }: ScanModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered size='sm'>
      <div className='modal-content'>
        <ModalBody className='tw-p-2 tw-flex tw-flex-col tw-items-center tw-gap-4'>
          <TabContent>
            <TabPane>
              <div className='text-center'>
                <div className='avatar-md mx-auto'>
                  <div className='avatar-title rounded-circle bg-light'>
                    <i className='bx bxs-lock h1 mb-0 text-danger'></i>
                  </div>
                </div>
                <div className='p-2 mt-4'>
                  <h4>You have no permission</h4>
                  <p>You need to be authorized to perform this action</p>
                  <div className='mt-4'>
                    <Button className='btn btn-danger w-md' onClick={onCloseClick}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ScanModal;
