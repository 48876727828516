import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import { defaultParticipant } from 'data/defaultEvent';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import EventCoreService from 'services/event-core.service';
import EventRoleService from 'services/event-role.service';
import { EventRole, Event, Participant, EventVerification } from 'types';

import ApproveModal from '../modal/ApproveModal';
import RoleModal from '../modal/RoleModal';

import SendRequestModal from './SendRequestModal';
import VerificationRequestInfo from './VerificationRequestInfo';
import VerificationRequestParticipants from './VerificationRequestParticipants';
import VerificationRequestRoles from './VerificationRequestRoles';

// type PreviewFile = File & {
//   preview: string;
// };

enum Tab {
  BASIC_INFORMATION = 'BASIC_INFORMATION',
  ATTENDEES = 'ATTENDEES',
  ROLES = 'ROLES',
}

const VerificationRequestDetail = () => {
  useTitle('Event detail', {
    restoreOnUnmount: true,
  });
  const { id } = useParams();
  const [event, setEvent] = useState<Event>();
  const [participants, setParticipants] = useState<Array<Participant>>([]);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.BASIC_INFORMATION);
  const [loading, setLoading] = useState(false);
  const [openRequestState, setOpenRequestState] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [request, setRequest] = useState<{ message: string } | null>(null);
  const [openRole, setOpenRole] = useState<EventRole | null>(null);
  const [roles, setRoles] = useState<Array<EventRole>>([]);
  const [filteredRoles, setFilteredRoles] = useState<Array<EventRole>>([]);
  const [eventVerification, setEventVerification] = useState<EventVerification>();

  const searchRoles = () => {
    const input = document.getElementById('searchbarRole') as HTMLInputElement;
    const filteredList = roles.filter((role: EventRole) => {
      return role.title.toLowerCase().includes(input.value.toLowerCase());
    });
    setFilteredRoles(filteredList);
  };

  const getEventRoles = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventRoleService.getEventRolesList(id || '');
      const { payload } = data;
      setRoles(payload);
      setFilteredRoles(payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  useEffect(() => {
    getEventRoles();
  }, [getEventRoles]);

  const fetchEvent = useCallback(async () => {
    try {
      setLoading(true);
      if (!id) return;
      const { data } = await EventCoreService.getEventById(id);
      const { payload } = data;
      setEvent(payload);
      setParticipants([defaultParticipant]);
    } catch (error: unknown) {
      // handleAxiosError(error, (message) => toast.error(message));
      toast.error('Somethings went wrong');
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  const getEventVerification = useCallback(async () => {
    try {
      const eventId = event?._id;
      if (eventId) {
        const { data } = await EventCoreService.getEventVerificationById({
          eventId,
          limit: 12,
          offset: 0,
        });
        const { payload } = data;
        setEventVerification(payload.verifications[0]);
      }
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [event?._id]);

  useEffect(() => {
    getEventVerification();
  }, [getEventVerification]);

  const handleSubmitReview = async (mess: string, isVerified: boolean) => {
    if (!eventVerification) {
      toast.error('Event verification data is not available.');
      return;
    }
    try {
      const verificationId = eventVerification._id;
      await EventCoreService.submitReview(verificationId, isVerified, mess);
      if (!isVerified) {
        toast.success('Review submitted successfully');
      }
      setOpenRequestState(false);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const handleApprove = async (/* mess : string */) => {
    if (!eventVerification) {
      toast.error('Event verification data is not available.');
      return;
    }
    if (eventVerification?.isMerged === true) {
      toast.error('Event has verified.');
      setOpenApproveModal(false);
      return;
    }
    try {
      const verificationId = eventVerification._id;
      handleSubmitReview('Approved', true);
      await EventCoreService.approveVerification(verificationId);
      toast.success('Event is approved successfully');
      setOpenApproveModal(false);
    } catch (error) {
      handleAxiosError(error, (message) => {
        toast.error(message);
      });
    }
  };

  if (loading || !event || !eventVerification)
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumb title='Application' breadcrumbItem='Event detail' />
          </Container>
          <Col>
            <Card className='tw-relative tw-p-4 xl:tw-p-[1.5rem] tw-flex tw-flex-col tw-items-center lg:tw-flex-row lg:tw-justify-between'>
              <Loading width={400} height={400} />
            </Card>
          </Col>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <ApproveModal
        show={openApproveModal}
        onCloseClick={() => setOpenApproveModal(false)}
        onApproveClick={() => {
          handleApprove(/* mess: string */);
        }}
      />

      <SendRequestModal
        show={request !== null}
        onCloseClick={() => setRequest(null)}
        onSendClick={() => {
          console.log(request);
          if (request !== null) handleSubmitReview(request?.message, false);
          setRequest(null);
          setOpenRequestState(false);
        }}
      />

      <RoleModal role={openRole} onCloseClick={() => setOpenRole(null)} />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Basic' />
        </Container>
        <Col>
          <Nav tabs role='tablist' className='nav-tabs-custom'>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === Tab.BASIC_INFORMATION,
                })}
                onClick={() => {
                  setActiveTab(Tab.BASIC_INFORMATION);
                }}
              >
                Basic information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === Tab.ATTENDEES,
                })}
                onClick={() => {
                  setActiveTab(Tab.ATTENDEES);
                }}
              >
                Attendees
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === Tab.ROLES,
                })}
                onClick={() => {
                  setActiveTab(Tab.ROLES);
                }}
              >
                Roles
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={Tab.BASIC_INFORMATION} id={Tab.BASIC_INFORMATION}>
                <VerificationRequestInfo
                  event={event}
                  eventVerification={eventVerification}
                  openApproveModal={() => setOpenApproveModal(true)}
                  openRequestState={openRequestState}
                  setOpenRequestState={setOpenRequestState}
                  onSendRequest={(message: string) => {
                    setRequest({ message });
                  }}
                />
              </TabPane>
              <TabPane tabId={Tab.ATTENDEES} id={Tab.ATTENDEES}>
                <VerificationRequestParticipants participants={participants} />
              </TabPane>
              <TabPane tabId={Tab.ROLES} id={Tab.ROLES}>
                <VerificationRequestRoles
                  setOpenRole={setOpenRole}
                  roles={filteredRoles}
                  searchRoles={searchRoles}
                />
              </TabPane>
            </TabContent>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default VerificationRequestDetail;
