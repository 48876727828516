import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { ReactComponent as Minus } from '../../../assets/svg/minus.svg';

interface CloseModalProps {
  show: boolean;
  title: string;
  content?: string;
  subContent?: string;
  onCloseEventClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onCloseClick: React.MouseEventHandler | undefined;
  disabled?: boolean;
}

const CloseModal = ({
  show,
  title,
  content,
  subContent,
  onCloseEventClick,
  onCloseClick,
  disabled,
}: CloseModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className='modal-content'>
        <ModalBody className='px-4 py-5 text-center'>
          <button
            type='button'
            onClick={onCloseClick}
            className='btn-close position-absolute end-0 top-0 m-3'
          ></button>
          <div className='avatar-sm mb-4 mx-auto'>
            <div className='avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3'>
              <Minus />
            </div>
          </div>
          <p className='tw-font-semibold font-size-24'>{title}</p>
          {content && <p className='tw-text-[16px] mb-4'>{content}</p>}
          {subContent && <p className='tw-text-[16px] mb-4'>{subContent}</p>}
          <div className='hstack gap-3 justify-content-center mb-0'>
            <button
              type='button'
              disabled={disabled}
              className='btn btn-danger'
              onClick={onCloseEventClick}
            >
              Close
            </button>
            <button type='button' className='btn btn-secondary' onClick={onCloseClick}>
              Cancel
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default CloseModal;
