// import { startCase } from 'lodash';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import * as Yup from 'yup';

import ToggleBadge from 'Components/Common/ToggleBadge';
import { EventPermissions, EventRole } from 'types';

type EditRoleModalProps = {
  role: EventRole | null;
  onCloseClick: () => void;
  onUpdateClick: (
    roleId: string,
    role: {
      _id: string;
      title: string;
      description: string;
      maxRegistration: number;
      permissions: EventPermissions[];
    }
  ) => Promise<void>;
  onDeleteClick: (role: EventRole) => Promise<void>;
};

const allEventPermissions = Object.values(EventPermissions);

const EditRoleModal = ({
  role,
  onCloseClick,
  onUpdateClick,
  onDeleteClick,
}: EditRoleModalProps) => {
  const [permissions, setPermissions] = useState<EventPermissions[]>();

  useEffect(() => {
    if (role?.permissions) setPermissions(role?.permissions);
  }, [role]);

  const addPermission = (permission: EventPermissions) => {
    setPermissions((oldPermissions) => [...new Set(oldPermissions?.concat([permission]))]);
  };

  const removePermission = (permission: EventPermissions) => {
    setPermissions((oldPermissions) => oldPermissions?.filter((p) => p !== permission));
  };

  const validation = useFormik({
    initialValues: {
      title: role?.title || '',
      description: role?.description || '',
      maxRegistration: role?.maxRegistration || 0,
    },
    enableReinitialize: true,
    isInitialValid: false,
    validationSchema: Yup.object({
      title: Yup.string().required('Please enter role title'),
      description: Yup.string().required('Please enter role description'),
      maxRegistration: Yup.number()
        .integer()
        .min(1)
        .required('Please enter max registration number'),
    }),
    onSubmit: async (values) => {
      if (permissions && permissions.length !== 0) {
        await onUpdateClick(role?._id || '', {
          _id: role?._id || '',
          title: values.title,
          description: values.description,
          maxRegistration: values.maxRegistration,
          permissions: permissions ?? [],
        });
      } else {
        toast.error('Please assign at least one permission to this role.');
      }
    },
  });

  if (!role)
    return (
      <Modal isOpen={role !== null} toggle={onCloseClick} centered={true} size='md'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Role</h5>
        </div>
        <div className='modal-content'>
          <ModalBody className='px-4 py-4 tw-flex tw-flex-col tw-gap-4 '></ModalBody>
        </div>
      </Modal>
    );
  return (
    <Modal
      isOpen={role !== null}
      toggle={onCloseClick}
      centered
      size='lg'
      contentClassName='tw-rounded-[0.5rem]'
    >
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>{role.title}</h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete='off'
        >
          <div className='tw-flex tw-flex-col tw-gap-2'>
            <FormGroup row>
              <Label className='tw-font-bold col-form-label col-lg-3' htmlFor='title'>
                Title:
              </Label>
              <Col lg={9}>
                <Input
                  type='text'
                  id='title'
                  className='form-control'
                  name='title'
                  placeholder='Event title...'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ''}
                  invalid={validation.touched.title && validation.errors.title ? true : false}
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type='invalid'>{validation.errors.title}</FormFeedback>
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label className='tw-font-bold col-form-label col-lg-3' htmlFor='description'>
                Description:
              </Label>
              <Col lg={9}>
                <Input
                  tag='textarea'
                  id='description'
                  rows={5}
                  className='form-control'
                  name='description'
                  placeholder='Event description...'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ''}
                  invalid={
                    validation.touched.description && validation.errors.description ? true : false
                  }
                />
                {validation.touched.description && validation.errors.description ? (
                  <FormFeedback type='invalid'>{validation.errors.description}</FormFeedback>
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label className='tw-font-bold col-form-label col-lg-3' htmlFor='maxRegistration'>
                Max registration:
              </Label>
              <Col lg={9}>
                <Input
                  type='number'
                  id='maxRegistration'
                  className='form-control'
                  name='maxRegistration'
                  placeholder='Event max registration...'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.maxRegistration || ''}
                  invalid={
                    validation.touched.maxRegistration && validation.errors.maxRegistration
                      ? true
                      : false
                  }
                />
                {validation.touched.maxRegistration && validation.errors.maxRegistration ? (
                  <FormFeedback type='invalid'>{validation.errors.maxRegistration}</FormFeedback>
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <span className='tw-font-bold col-form-label col-lg-3'>Permissions:</span>
              <Col
                lg={9}
                className='tw-h-fit tw-max-w-full tw-flex tw-flex-row tw-gap-x-2 tw-gap-y-2 tw-overflow-hidden tw-flex-wrap '
              >
                {allEventPermissions.map((permission) => (
                  <ToggleBadge
                    key={permission}
                    selected={permissions?.includes(permission) ?? false}
                    toggleOn={addPermission}
                    toggleOff={removePermission}
                    eventPermission={permission}
                  />
                ))}
              </Col>
            </FormGroup>

            {role.title !== 'Event Creator' && role.title !== 'Participant' && (
              <div className='tw-flex tw-relative tw-flex-row tw-self-end tw-gap-4'>
                <Button
                  onClick={() => {
                    onDeleteClick(role);
                    onCloseClick();
                  }}
                  color='danger'
                  disabled={false}
                >
                  Delete role
                </Button>
                <Button type='submit' color='primary' disabled={false}>
                  Update role
                </Button>
              </div>
            )}
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditRoleModal;
