import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import withRouter from 'Components/Common/withRouter';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import AuthService from 'services/auth.service';

type PasswordReset = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  useTitle('Reset Password');
  const [searchParams] = useSearchParams({ ticket: '' });
  const { ticket } = Object.fromEntries(searchParams);
  const [show, setShow] = React.useState(false);
  const [confirmShow, setConfirmShow] = React.useState(false);

  const navigate = useNavigate();

  const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{8,}$/;

  const formik = useFormik<PasswordReset>({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(
          passwordRegex,
          'Password must be at least 8 characters, include at least one number, one uppercase letter, one lowercase letter, and one special character.'
        )
        .required('Please Enter Your Password'),
      confirmPassword: Yup.string()
        .required('Please Enter Your Confirm Password')
        .oneOf([Yup.ref('password'), null], 'Password not match'),
    }),
    onSubmit: (values) => {
      AuthService.resetPassword(ticket, values.password, values.confirmPassword)
        .then(() => {
          toast.success('Change password Successfully');
          setTimeout(() => {
            navigate('/login');
          });
        })
        .catch((error: unknown) => {
          handleAxiosError(error, (message) => toast.error(message));
        });
    },
  });

  useEffect(() => {
    const checkTicket = async () => {
      try {
        if (!ticket) navigate('/not-found');
        const { data } = await AuthService.validateResetPasswordTicket(ticket);

        const { payload: isValid } = data;

        console.log(isValid);

        if (!isValid) {
          navigate('/not-found');
        }
      } catch (error) {
        navigate('/not-found');
      }
    };

    checkTicket();
  }, [ticket, navigate]);

  if (!ticket) return <Navigate to='/' />;

  return (
    <React.Fragment>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <div className='bg-info bg-soft'>
                  <Row>
                    <Col className='col-8 d-flex align-items-center '>
                      <div className='text-primary p-4'>
                        <strong className='h3 text-primary'>Reset Password</strong>
                        <p>OISP Union App</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className='pt-0'>
                  <div className='p-2'>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                      className='form-horizontal'
                    >
                      <div className='mb-3'>
                        <Label className='form-label'>Password</Label>
                        <div className='input-group'>
                          <Input
                            name='password'
                            value={formik.values.password || ''}
                            type={show ? 'text' : 'password'}
                            placeholder='Enter Password'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.password && formik.errors.password ? true : false
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className='btn btn-light rounded-tl-none rounded-tr-[4px] rounded-br-[4px] rounded-bl-none'
                            type='button'
                            id='password-addon'
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                          {formik.touched.password && formik.errors.password ? (
                            <FormFeedback type='invalid'>{formik.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className='mb-3'>
                        <Label className='form-label'>Confirm Password</Label>
                        <div className='input-group'>
                          <Input
                            name='confirmPassword'
                            value={formik.values.confirmPassword || ''}
                            type={confirmShow ? 'text' : 'password'}
                            placeholder='Confirm Your Password'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.confirmPassword && formik.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setConfirmShow(!confirmShow)}
                            className='btn btn-light rounded-tl-none rounded-tr-[4px] rounded-br-[4px] rounded-bl-none'
                            type='button'
                            id='password-addon'
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <FormFeedback type='invalid'>
                              {formik.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className='mt-3 d-grid'>
                        <button
                          type='button'
                          className='btn btn-primary btn-block '
                          onClick={() => formik.handleSubmit()}
                        >
                          Change Password
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
