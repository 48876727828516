import { useNavigate } from 'react-router-dom';

import { EventStatus, Event } from 'types';

import defaultEventThumbnail from '../../assets/images/eventDefaultThumnail.png';

type VerificationRequestCardProps = {
  event: Event | null;
  isShowVerify: boolean;
};

const VerificationRequestCard = ({ event, isShowVerify }: VerificationRequestCardProps) => {
  const navigate = useNavigate();
  if (!event) return null;
  return (
    <div className='tw-relative tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-[17.5rem] lg:tw-w-[20rem] 2xl:tw-w-[25rem] tw-h-[9rem] tw-overflow-hidden tw-shadow-xl tw-p-2 tw-bg-white tw-rounded-lg tw-border-solid tw-border-[1px] tw-border-[#3D4863]'>
      <img
        alt={`thumbnail of ${event.title}`}
        src={event.poster?.original || defaultEventThumbnail}
        className='tw-w-[6rem] tw-h-[8rem] tw-object-cover tw-rounded-lg'
      />
      <div className='tw-relative tw-flex tw-flex-col tw-w-[10rem] lg:tw-w-[12.5rem] 2xl:tw-w-[17rem] tw-h-[8rem] tw-text-[12px] tw-gap-2'>
        <div className='tw-font-bold tw-truncate tw-w-full'>{event.title}</div>
        <div className='tw-overflow-y-hidden tw-h-[2rem] tw-w-full'>{event.description}</div>
        <div className='tw-truncate tw-w-full'>by {event.createdBy.name}</div>
        <div className='tw-self-end tw-font-bold tw-cursor-pointer tw-mt-3'>
          {event.status === EventStatus.PRE_EVENT && isShowVerify === true && (
            <button
              onClick={() => navigate('/event/verification-requests/' + event._id)}
              className='tw-bg-primary tw-rounded-[6px] tw-h-[26px] tw-w-[50px] tw-flex tw-items-center tw-justify-center'
            >
              <div className='tw-text-white tw-text-[12px] tw-text-center '>Verify</div>
            </button>
          )}
          {event.status !== EventStatus.PRE_EVENT && isShowVerify === true && (
            <button
              className='tw-flex tw-flex-col'
              onClick={() => navigate('/event/verification-requests/' + event._id)}
            >
              <div className='tw-text-primary tw-text-[10px] tw-text-center '>Verified </div>
              <div className='tw-w-full tw-h-[1px] tw-bg-primary'></div>
            </button>
          )}
          {isShowVerify === false && (
            <button
              className='tw-flex tw-flex-col tw-ml-4'
              onClick={() => navigate('/event/' + event._id)}
            >
              <div className='tw-text-primary tw-text-[10px] tw-text-center '>View detail</div>
              <div className='tw-w-full tw-h-[1px] tw-bg-primary'></div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerificationRequestCard;
