import _ from 'lodash';

import { CertParameter, Certificate, CertificateBatch, CertificateTemplate } from 'types';

const getParameterValue = (
  param: string,
  batch: CertificateBatch,
  template: CertificateTemplate
): CertParameter => {
  const paramIndex = _.findIndex(template.parameters, { key: param });

  if (
    template.parameters[paramIndex].paramType &&
    template.parameters[paramIndex].paramType === 'image' &&
    batch[`${param}Url`]
  ) {
    return {
      ...template.parameters[paramIndex],
      value: batch[`${param}Url`] as string,
    };
  }

  if (param === 'decisionDate') {
    const decisionDate = new Date(batch.decisionDate || 0);
    const date = `${decisionDate.getDate() < 10 ? '0' : ''}${decisionDate.getDate()}`;
    const decisionMonth = decisionDate.getMonth() + 1;
    const month = `${decisionMonth < 10 ? '0' : ''}${decisionMonth}`;

    return {
      ...template.parameters[paramIndex],
      value: `${date}/${month}/${decisionDate.getFullYear()}`,
    };
  }

  return {
    ...template.parameters[paramIndex],
    value: batch[param] as string,
  };
};

const getAllParams = (certificate: Certificate, template: CertificateTemplate) => {
  let parameters: Array<CertParameter> = [];

  parameters = parameters.concat(certificate.parameters);

  const specialParameters = [
    'signee',
    'signeePosition',
    'signature',
    'decisionId',
    'decisionDate',
    'decisionQr',
  ];

  specialParameters.forEach((param) => {
    if (certificate.batch[param] || certificate.batch[`${param}Url`])
      parameters = parameters.concat(getParameterValue(param, certificate.batch, template));
  });

  return parameters;
};

export default getAllParams;
