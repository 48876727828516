import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Col, Container, Input } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Pagination from 'Components/Common/Pagination';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import EventCoreService from 'services/event-core.service';
import { EventVerification } from 'types/core';

import Filter from '../../../assets/svg/Group702.svg';
import VerificationRequestCard from '../VerificationRequestCard';

const limit = 12;

const EventVerificationRequests = () => {
  useTitle('Verification requests', {
    restoreOnUnmount: true,
  });

  const [requestCount, setRequestCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [queryOrder, setQueryOrder] = useState<'verified' | 'unverified'>('unverified');
  const [loading, setLoading] = useState(false);
  const [eventList, setEventList] = useState<EventVerification[]>([]);
  const getEventList = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventCoreService.getVerificationEvents({
        limit,
        offset: (currentPage - 1) * limit,
        order: 'asc',
        filter: queryOrder,
      });
      const { payload } = data;
      const filteredEvents = payload.verifications;
      setEventList(filteredEvents);
      setRequestCount(payload.count);
      // setEventCount(filteredEvents.length)
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [queryOrder, currentPage]);

  useEffect(() => {
    getEventList();
  }, [getEventList]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Event verification requests' />
        </Container>
        <Col>
          <Card className=' tw-overflow-hidden'>
            <div className='tw-flex tw-w-full tw-items-center tw-justify-between tw-p-4'>
              <div className='tw-font-bold tw-text-left'>Verification requests</div>
              <div className='tw-text-right tw-flex tw-flex-row tw-gap-2'>
                <div>
                  <Input
                    id='orderSelect'
                    className='form-control !tw-w-full xs:!tw-w-[8rem] tw-h-[38px] tw-items-center tw-justify-center'
                    name='orderSelect'
                    defaultValue='unverified'
                    onChange={(e) => {
                      const order = e.target.value;
                      if (order === 'verified') setQueryOrder('verified');
                      else setQueryOrder('unverified');
                    }}
                    type='select'
                    placeholder='Order'
                  >
                    <option key='unverified' value='unverified'>
                      Not Verified
                    </option>
                    <option key='verified' value='verified'>
                      Verified
                    </option>
                  </Input>
                </div>
                <img alt={'filter'} src={Filter} />
              </div>
            </div>
            <div className='tw-relative tw-flex tw-flex-col tw-w-full tw-gap-5 tw-p-4'>
              <div className='tw-relative tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-[1rem] tw-justify-items-center'>
                {eventList.length > 0 && !loading ? (
                  eventList.map((event) => (
                    <VerificationRequestCard
                      key={event._id}
                      event={event.event}
                      isShowVerify={true}
                    />
                  ))
                ) : (
                  <div></div>
                )}
              </div>
              <Pagination
                count={requestCount}
                pageSize={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventVerificationRequests;
