export const hhmmToSeconds = (time: string) => {
  const seconds = new Date('1970-01-01T' + time + ':00Z').getTime() / 1000;
  return seconds;
};

export const secondsToHhmm = (seconds: number) => {
  const hhmm = new Date(seconds * 1000).toISOString().substring(11, 16);
  return hhmm;
};

export const secondsToDate = (seconds: number): string => {
  const date = new Date(seconds);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`;
};

export const secondsToTimeDate = (seconds: number): string => {
  const date = new Date(seconds);

  const hour = date.getHours();
  const minute = date.getMinutes();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}   ${
    day < 10 ? `0${day}` : day
  }/${month < 10 ? `0${month}` : month}/${year}`;
};
