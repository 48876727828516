import { createColumnHelper } from '@tanstack/react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { useDebounceCallback } from 'usehooks-ts';

import Breadcrumb from 'Components/Common/Breadcrumb';
import DeleteModal from 'Components/Common/DeleteModal';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import TableContainer from 'Components/Common/TableContainer';
// import formatDate from 'helpers/formatDate';
import { handleAxiosError } from 'helpers/handleError';
import { secondsToDate } from 'helpers/timeConverter';
import { toStartCase } from 'helpers/translate';
import EventCoreService from 'services/event-core.service';
import {
  DisplayEventStatus,
  Event,
  EventPermissions,
  EventStatus,
  EventVerification,
} from 'types/core';

import { ReactComponent as Archive } from '../../assets/svg/archive.svg';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import { ReactComponent as Delete } from '../../assets/svg/delete.svg';
import { ReactComponent as Filter } from '../../assets/svg/FilterIcon.svg';
import { ReactComponent as ModeEdit } from '../../assets/svg/mode_edit.svg';
import { ReactComponent as Option } from '../../assets/svg/Option.svg';
import { ReactComponent as QrIcon } from '../../assets/svg/qrIcon.svg';
import { ReactComponent as Restore } from '../../assets/svg/unarchive.svg';
import ParticipantService from '../../services/participant.service';

import ArchiveModal from './modal/ArchiveModal';
import CloseModal from './modal/CloseModal';
import FilterModal from './modal/FilterModal';
import RestoreModal from './modal/RestoreModal';
import VerificationRequestCard from './VerificationRequestCard';

const limit = 10;

const EventList = () => {
  const [queryTitle, setQueryTitle] = useState('');
  const debounceTitle = useDebounceCallback(setQueryTitle, 500);

  const [queryCreatedBy, setQueryCreatedBy] = useState<string | undefined>(undefined);
  const [queryOrder, setQueryOrder] = useState<'asc' | 'desc'>('desc');
  const [queryStatus, setQueryStatus] = useState<EventStatus | undefined>(undefined);
  const [eventList, setEventList] = useState<Event[]>([]);
  const [eventListVerification, setEventListVerification] = useState<EventVerification[]>([]);
  const [eventCount, setEventCount] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string>('');
  const [selectedDropdownId, setSelectedDropdownId] = useState<string | null>(null);
  const [selectedDeleteEventId, setSelectedDeleteEventId] = useState<string | null>(null);
  const [selectedArchiveEventId, setSelectedArchiveEventId] = useState<string | null>(null);
  const [selectedRestoreEventId, setSelectedRestoreEventId] = useState<string | null>(null);
  const [selectedCloseEventId, setSelectedCloseEventId] = useState<string | null>(null);
  const openDeleteModal = (eventId: string) => setSelectedDeleteEventId(eventId);
  const closeDeleteModal = () => setSelectedDeleteEventId(null);
  const openArchiveModal = (eventId: string) => setSelectedArchiveEventId(eventId);
  const closeArchiveModal = () => setSelectedArchiveEventId(null);
  const openRestoreModal = (eventId: string) => setSelectedRestoreEventId(eventId);
  const closeRestoreModal = () => setSelectedRestoreEventId(null);
  const openCloseModal = (eventId: string) => setSelectedCloseEventId(eventId);
  const closeCloseModal = () => setSelectedCloseEventId(null);
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const columnHelper = createColumnHelper<Event>();

  const navigate = useNavigate();

  const searchEventTitle = () => {
    const input = document.getElementById('searchbarEventTitle') as HTMLInputElement;
    debounceTitle(input.value);
  };
  const handleApplyFilters = (filters: {
    order: 'asc' | 'desc';
    status?: EventStatus;
    creator?: string;
  }) => {
    setQueryOrder(filters.order);
    setQueryStatus(filters.status);
    setQueryCreatedBy(filters.creator);
    setOpenFilterModal(false);
  };

  const getUnverifiedEventList = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventCoreService.getVerificationEvents({
        limit,
        offset: (currentPage - 1) * limit,
        order: 'asc',
        filter: 'unverified',
      });
      const { payload } = data;
      const filteredEvents = payload.verifications;
      setEventListVerification(filteredEvents);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [currentPage]);
  useEffect(() => {
    getUnverifiedEventList();
  }, [getUnverifiedEventList]);

  const getEventList = useCallback(async () => {
    try {
      setLoading(true);
      const offset = (currentPage - 1) * limit;
      const { data } = await EventCoreService.getParticipatedEvents(
        queryOrder,
        limit,
        offset,
        queryTitle,
        queryStatus,
        queryCreatedBy
      );
      const { payload } = data;
      setEventList(payload.eventList);
      setEventCount(payload.count);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [currentPage, queryStatus, queryOrder, queryTitle, queryCreatedBy]);

  useEffect(() => {
    getEventList();
  }, [getEventList]);

  const handleDelete = async (eventId: string) => {
    const updatedPermissions = await ParticipantService.getMyPermissions(eventId);
    if (
      updatedPermissions.data.payload.includes(EventPermissions.LEADER) ||
      updatedPermissions.data.payload.includes(EventPermissions.DELETE_EVENT)
    ) {
      try {
        await EventCoreService.deleteEvent(eventId);
        toast.success('Event is deleted successfully');
        getEventList();
      } catch (error) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        closeDeleteModal();
      }
    } else {
      toast.error('You do not have permission to delete this event.');
      closeDeleteModal();
    }
  };

  const handleArchive = async (eventId: string) => {
    const updatedPermissions = await ParticipantService.getMyPermissions(eventId);
    if (
      updatedPermissions.data.payload.includes(EventPermissions.LEADER) ||
      updatedPermissions.data.payload.includes(EventPermissions.DELETE_EVENT)
    ) {
      try {
        await EventCoreService.updateEventStatus(eventId, EventStatus.ARCHIVED as EventStatus);
        toast.success('Event is archived successfully');
        getEventList();
      } catch (error) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        closeArchiveModal();
      }
    } else {
      toast.error('You do not have permission to delete this event.');
      closeArchiveModal();
    }
  };
  const handleRestore = async (eventId: string) => {
    const updatedPermissions = await ParticipantService.getMyPermissions(eventId);
    if (
      updatedPermissions.data.payload.includes(EventPermissions.LEADER) ||
      updatedPermissions.data.payload.includes(EventPermissions.DELETE_EVENT)
    ) {
      try {
        await EventCoreService.restoreEvent(eventId);
        toast.success('Event is restored successfully');
        getEventList();
      } catch (error) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        closeRestoreModal();
      }
    } else {
      toast.error('You do not have permission to delete this event.');
      closeRestoreModal();
    }
  };
  const handleClose = async (eventId: string) => {
    const updatedPermissions = await ParticipantService.getMyPermissions(eventId);
    if (
      updatedPermissions.data.payload.includes(EventPermissions.LEADER) ||
      updatedPermissions.data.payload.includes(EventPermissions.CLOSE_EVENT)
    ) {
      try {
        await EventCoreService.updateEventStatus(eventId, EventStatus.POST_EVENT);
        toast.success('Event is closed successfully');
        getEventList();
      } catch (error) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        closeCloseModal();
      }
    } else {
      toast.error('You do not have permission to close this event.');
      closeCloseModal();
    }
  };

  const isArchivedStatus = eventList.every((event) => event.status === EventStatus.ARCHIVED);
  const columns = [
    columnHelper.accessor('_id', {
      header: '#',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div>{cellProps.row.index + 1}</div>;
      },
      footer: (props) => props.column.id,
    }),

    columnHelper.accessor('title', {
      header: 'Name',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div className='tw-max-w-[13rem] tw-truncate'>{cellProps.getValue()}</div>;
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('createdBy', {
      header: 'Creator',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        const creator = cellProps.getValue();
        const uniqueUser = `id-${creator._id}`;
        return (
          <div>
            <div id={uniqueUser}>{creator.email}</div>
            <UncontrolledTooltip placement='top' target={uniqueUser}>
              {creator.name}
            </UncontrolledTooltip>
          </div>
        );
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created at',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div>{secondsToDate(cellProps.getValue())}</div>;
      },
      footer: (props) => props.column.id,
    }),

    ...(!isArchivedStatus
      ? [
          columnHelper.accessor('status', {
            header: 'Phase',
            enableColumnFilter: false,
            enableGlobalFilter: false,
            cell: (cellProps) => {
              const currentEvent = cellProps.row.original;
              let displayStatus = toStartCase(cellProps.getValue());
              let badgeColor = 'primary';
              if (
                currentEvent.status === EventStatus.ON_EVENT &&
                currentEvent.startAt > Date.now()
              ) {
                displayStatus = toStartCase(DisplayEventStatus.UPCOMING);
                badgeColor = 'warning';
              } else if (
                currentEvent.status === EventStatus.ON_EVENT &&
                currentEvent.startAt <= Date.now() &&
                currentEvent.endAt >= Date.now()
              ) {
                badgeColor = 'success';
              } else if (
                currentEvent.status === EventStatus.ON_EVENT &&
                currentEvent.endAt <= Date.now()
              ) {
                displayStatus = toStartCase(DisplayEventStatus.ENDED);
                badgeColor = 'danger';
              }
              return (
                <Badge
                  color={badgeColor}
                  style={{
                    borderRadius: '0.5rem',
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  {displayStatus}
                </Badge>
              );
            },
            footer: (props) => props.column.id,
          }),
        ]
      : []),

    columnHelper.accessor('_id', {
      header: () => {
        return <div className='tw-w-full tw-flex tw-flex-row tw-justify-end'>Actions</div>;
      },
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableSorting: false,
      cell: (cellProps) => {
        const currentEvent = cellProps.row.original;
        return (
          <div className='tw-flex tw-flex-row tw-gap-[0.5rem] tw-justify-end'>
            <button
              onClick={() => navigate(`/event/${currentEvent._id}`)}
              className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
              id='detailEventTooltip'
            >
              <ModeEdit width={16} height={16} fill='#3D4863' />
              <UncontrolledTooltip placement='top' target='detailEventTooltip'>
                Detail
              </UncontrolledTooltip>
            </button>

            <button
              onClick={() => navigate(`/event/management/${currentEvent._id}`)}
              className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
              id='manageEventTooltip'
            >
              <QrIcon width={20} height={20} fill='#3D4863' />
              <UncontrolledTooltip placement='top' target='manageEventTooltip'>
                Scan
              </UncontrolledTooltip>
            </button>
            <Dropdown
              isOpen={selectedDropdownId === currentEvent._id}
              toggle={() => {
                setSelectedDropdownId(
                  selectedDropdownId === currentEvent._id ? null : currentEvent._id
                );
              }}
              className='tw-inline-block'
            >
              <DropdownToggle
                tag='button'
                className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
              >
                <Option width={14} height={14} fill='#3D4863' />
              </DropdownToggle>
              <DropdownMenu>
                {!isArchivedStatus && (
                  <DropdownItem
                    onClick={() => {
                      setSelectedArchiveEventId(currentEvent._id);
                      openArchiveModal(currentEvent._id);
                      console.log(selectedEventId);
                    }}
                    className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
                  >
                    <div className='tw-flex tw-flex-row tw-gap-x-4 tw-items-center '>
                      <Archive width={16} height={16} fill='#3D4863' />
                      <span className='tw-font-semibold tw-text-[#3D4863] tw-text-[16px]'>
                        Archive
                      </span>
                    </div>

                    {selectedArchiveEventId === currentEvent._id && (
                      <ArchiveModal
                        show={selectedArchiveEventId === currentEvent._id}
                        title='Archive Event'
                        eventStatus={currentEvent.status}
                        disabled={loading}
                        onDeleteClick={() => {
                          handleArchive(selectedArchiveEventId);
                        }}
                        onCloseClick={closeArchiveModal}
                      />
                    )}
                  </DropdownItem>
                )}
                {isArchivedStatus && (
                  <DropdownItem
                    onClick={() => {
                      setSelectedEventId(currentEvent._id);
                      openRestoreModal(currentEvent._id);
                    }}
                    className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
                  >
                    <div className='tw-flex tw-flex-row tw-gap-x-4 tw-items-center '>
                      <Restore width={16} height={16} fill='#3D4863' />
                      <span className='tw-font-semibold tw-text-[#3D4863] tw-text-[16px]'>
                        Restore
                      </span>
                    </div>
                    {selectedRestoreEventId === currentEvent._id && (
                      <RestoreModal
                        show={selectedRestoreEventId === currentEvent._id}
                        title='Restore Event'
                        content='Are you sure you want to restore this event?'
                        disabled={loading}
                        onDeleteClick={() => {
                          handleRestore(selectedRestoreEventId);
                        }}
                        onCloseClick={closeRestoreModal}
                      />
                    )}
                  </DropdownItem>
                )}
                {isArchivedStatus && (
                  <DropdownItem
                    onClick={() => {
                      setSelectedEventId(currentEvent._id);
                      openDeleteModal(currentEvent._id);
                    }}
                    className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
                  >
                    {' '}
                    <div className='tw-flex tw-flex-row tw-gap-x-4 tw-items-center '>
                      <Delete width={16} height={16} fill='#3D4863' />
                      <span className='tw-font-semibold tw-text-[#3D4863] tw-text-[16px]'>
                        Delete
                      </span>
                    </div>
                    {selectedDeleteEventId === currentEvent._id && (
                      <DeleteModal
                        show={selectedDeleteEventId === currentEvent._id}
                        title='Delete Event'
                        content="This action is very dangerous and can't be undone!"
                        disabled={loading}
                        onDeleteClick={() => {
                          handleDelete(selectedDeleteEventId);
                        }}
                        onCloseClick={closeDeleteModal}
                      />
                    )}
                  </DropdownItem>
                )}
                {currentEvent.status === EventStatus.ON_EVENT &&
                  currentEvent.endAt <= Date.now() && (
                    <DropdownItem
                      onClick={() => {
                        setSelectedEventId(currentEvent._id);
                        openCloseModal(currentEvent._id);
                      }}
                      className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
                    >
                      <div className='tw-flex tw-flex-row tw-gap-x-4 tw-items-center '>
                        <Close width={16} height={16} fill='#3D4863' />
                        <span className='tw-font-semibold tw-text-[#3D4863] tw-text-[16px]'>
                          Close
                        </span>
                      </div>
                      {selectedCloseEventId === currentEvent._id && (
                        <CloseModal
                          show={selectedCloseEventId === currentEvent._id}
                          title='Close Event'
                          content='This event will be changed  to the Post-event phase! '
                          subContent='Are you sure you want to close this event?'
                          disabled={loading}
                          onCloseEventClick={() => {
                            handleClose(selectedCloseEventId);
                          }}
                          onCloseClick={closeCloseModal}
                        />
                      )}
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      },
      footer: (props) => props.column.id,
    }),
  ];

  return (
    <React.Fragment>
      <FilterModal
        show={openFilterModal}
        onCloseClick={() => setOpenFilterModal(false)}
        onApplyFilters={handleApplyFilters}
        currentOrder={queryOrder}
        currentStatus={queryStatus}
        currentCreator={queryCreatedBy}
      />
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='All event' />
          <Row>
            <Col xs={12}>
              <Card>
                <div className='tw-p-5 tw-p-t-[1.5rem] md:tw-p-[2.5rem] md:tw-p-t-[1.75rem] tw-rounded-[0.25rem]'>
                  <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-3 tw-gap-2'>
                    <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
                      <i className='bx bx-search-alt' />
                      <Input
                        className='!tw-border-0'
                        onKeyUp={searchEventTitle}
                        id='searchbarEventTitle'
                        type='text'
                        placeholder='Search...'
                      />
                    </div>

                    {/* filter */}
                    <div className='tw-flex tw-h-[2.5rem]'>
                      <button
                        onClick={() => {
                          setOpenFilterModal(true);
                        }}
                        className='tw-rounded-[0.5rem] tw-bg-white tw-w-[40px] tw-h-full tw-border tw-border-[#3D4863] tw-flex tw-justify-center tw-items-center'
                      >
                        <Filter className='tw-w-[30px] tw-h-[30px] tw-mb-0' fill='#3D4863' />
                      </button>
                    </div>
                  </div>
                  {/* event verification section */}
                  {eventListVerification.some((event) => event.reviews?.length > 0) && (
                    <div>
                      <div className='tw-p-[1rem] tw-w-full tw-overflow-x-scroll tw-bg-primary tw-rounded-[0.5rem] tw-flex tw-flex-col tw-gap-y-4 tw-mb-4'>
                        <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-fit'>
                          <div className='tw-text-white tw-text-[0.875rem] sm:tw-text-base tw-h-fit tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                            <div className='tw-rounded-2xl tw-bg-white tw-w-3 tw-h-3'></div>
                            <span className='tw-hidden md:tw-block tw-font-bold'>
                              Your events have modification request
                            </span>
                            <span className='tw-block md:tw-hidden'>3 new events</span>
                          </div>
                        </div>
                        <div className='tw-flex tw-w-fit tw-flex-row tw-gap-x-4 tw-overflow-x-hidden scrollbar-transparent'>
                          {eventListVerification
                            .filter((event) => event.reviews?.length > 0) // Lọc các event có reviews.length > 0
                            .map((event) => (
                              <VerificationRequestCard
                                key={event._id}
                                event={event.event}
                                isShowVerify={false}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  )}

                  <Row className='mb-2'>
                    {!loading && (
                      <TableContainer
                        columns={columns}
                        tableClass='table align-middle table-nowrap'
                        theadClass='tw-bg-[#3D4863] tw-rounded-t-lg tw-text-white tw-font-bold'
                        data={eventList}
                        pageSize={limit}
                      />
                    )}
                    {loading && <Loading width={360} height={360} />}
                  </Row>
                  <div className='tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10'>
                    <Pagination
                      count={eventCount}
                      pageSize={limit}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EventList;
